
import { defineComponent } from "vue";
import Header from "@/components/parts/Header.vue";
import BottomNavigation from "@/components/parts/BottomNavigation.vue";

export default defineComponent({
  components: {
    Header,
    BottomNavigation,
  },
});
